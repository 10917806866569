<template>
  <div>
    <el-row class='table-search'>
      <el-col :span='24'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <el-date-picker
                v-if="isType===1"
              v-model='searchCondition.ym'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月度' style='width: 140px' @change='changeMonth'>
            </el-date-picker>
            <el-date-picker
                v-else
                v-model='searchCondition.ym'
                type='year'
                format='yyyy'
                value-format='yyyy'
                placeholder='选择年度' style='width: 140px' @change='changeMonth'>
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="isType===2">
            <el-popover
                placement="right"
                width="500"
                trigger="click"
            >
              <el-button size="small" type="default" slot="reference">
                选择季度<span v-if="searchCondition.quarter">: 第 {{ searchCondition.quarter }} 季度</span>
              </el-button>
              <el-row>
                <el-col :span="24">
                  <el-radio-group v-model="searchCondition.quarter" @change="changeQuarter">
                    <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item v-if="isType===3">
            <el-popover
                placement="right"
                width="500"
                trigger="click"
            >
              <el-button size="small" type="default" slot="reference">
                时间区间<span v-if="searchCondition.quarter">：{{
                  searchCondition.quarter === 1 ? '上半年' : searchCondition.quarter === 2 ? '下半年' : '未选择'
                }}</span>
              </el-button>
              <el-row>
                <el-col :span="24">
                  <el-radio-group v-model="searchCondition.quarter" @change="changeQuarter">
                    <el-radio-button v-for="(quarter) in years" :label="quarter.value" :key="quarter.label">
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-select v-model='searchCondition.dept_id' :placeholder='`可选项目组`'
                       @change='doSearch' @focus='loadOption'
                       clearable>
              <el-option v-for='(group,key) in options' :key='key' :label='group.dept_name'
                         :value='group.dept_id'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select @change='doSearch' v-model='searchCondition.status' placeholder='审核状态' style='width: 120px' clearable>
              <el-option v-for="item in statusOptions" :label='item.text' :key="item.id" :value='item.id'></el-option>

            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='changeMonth' icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <LogPanel :infoIds="kpi_dept_id" buttonSize="small" ref='refLogPanel'
                      v-if="kpi_dept_id!==null" module-name='pa_kpi'></LogPanel>
          </el-form-item>
          <el-form-item>
            <export-link class-name='default-export-btn' type='primary' plain ref='refExportLink' :lint-title='`导出`'
                         :can-export="true" @export='exportData' />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ExportLink from '@/components/export/ExportLink.vue'
import MultipleSelectMyGroup from '@/pages/groups/components/MultipleSelectMyGroup.vue'
import ExportBtn from '@/components/export/ExportBtn.vue'
import LogPanel from "@/components/logs/LogPanel.vue";

export default {
  name: 'BonusSearch',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    LogPanel,
    ExportBtn,
    ExportLink, MultipleSelectMyGroup
  },
  props: {
    isType: {
      type: [Number, String],
      default() {
        return 1
      }
    },
  },
  data() {
    return {
      searchCondition: {
        dept_id: null,
        ym: null,
        groups: [],
        depth: 0,
        status: null,
        quarter: 1,
        year: null,
        months: null
      },
      initLoading: false,
      options: [],
      quarters: [
        {label: '第1季度', value: 1},
        {label: '第2季度', value: 2},
        {label: '第3季度', value: 3},
        {label: '第4季度', value: 4},
      ],
      years: [
        {label: '未选年度', value: 0},
        {label: '上半年', value: 1},
        {label: '下半年', value: 2},
      ],
      range_date: null,
      statusOptions: [],
      kpi_dept_id: null
    }
  },
  methods: {
    changeQuarter() {
      //计算季度对应的月份
      if (this.isType === 2) {
        let quarter = this.searchCondition.quarter
        this.searchCondition.months = [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];//月份的集合
      } else {
        let quarter = this.searchCondition.quarter
        this.searchCondition.months = quarter === 1 ? [1, 2, 3, 4, 5, 6] : [7, 8, 9, 10, 11, 12];//月份的集合
        this.searchCondition.date_value = quarter //月份的集合
        this.range_date = quarter === 1 ? 1 : 7//月份的集合
      }
      this.changeMonth()


    },
    initMonth() {
      let date = new Date()
      //分页页面-默认上月
      date.setMonth(date.getMonth() - 1)
      let year = date.getFullYear() + ''
      let month = date.getMonth() + 1
      this.searchCondition.year = `${year}`
      this.searchCondition.months = [Number(month)]
      this.searchCondition.ym = `${year}-${month}`
      this.getOptions()

    },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(valArr, labelArr) {
      let dept_id = null
      let group_name = null

      if (valArr && valArr.length > 0) {
        this.searchCondition.depth = valArr.length
        let len = valArr.length
        dept_id = valArr[len - 1]
        group_name = labelArr[len - 1]
      }
      this.searchCondition.dept_id = dept_id

      await this.setGroupInfo(dept_id, group_name)
    },
    async setGroupInfo(dept_id, group_name) {
      // console.log('-----------------')
      // this.$refs['refDeptCascaderScope'].initVal(dept_id)
      if (dept_id) {
        this.$notify.success(`查看【${group_name}】奖金分配明细`)
      }

      await this.doSearch()
    },
    getNodeKpiId(val) {
      let obj = this.options.find(value => value.dept_id === val)
      return obj ? obj.kpi_dept_id : ''
    },
    async changeMonth() {
      await this.getOptions()

      await this.doSearch()

    },
    doSearch(val) {
      let searchCondition = this.handleSearchCondition()
      if (this.options.length > 0) {
        this.kpi_dept_id = this.getNodeKpiId(val)
      }
      this.$emit('doSearch', searchCondition)
    },
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dept_id
      }
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      if (this.searchCondition.ym) {
        let date = this.searchCondition.ym.split('-')
        condition.year = date[0]
        if (this.isType === 1) {
          condition.date_value = Number(date[1])
        }
      }
      if (this.isType) {
        condition.type = this.isType
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
        if (this.isType !== 1) {
          condition.date_value = this.searchCondition.quarter
        }
      }
      if (this.searchCondition.status != null && this.searchCondition.status !== '') {
        condition.status = this.searchCondition.status
      }
      return condition
    },
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `团队奖金-${searchCondition.year}年`
        if (searchCondition.type === 1) {
          name += `${searchCondition.date_value}月`
        } else if (searchCondition.type === 2) {
          name += `第${searchCondition.quarter}季度`
        } else {
          name += searchCondition.quarter === 1 ? '上半年' : '下半年'
        }

        let response = await this.$api.getShareExportList(searchCondition)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    async getOptions() {
      this.options = []
      let searchCondition = this.handleSearchCondition()
      if (this.searchCondition.ym && this.searchCondition.year) {
        if (searchCondition?.dept_id) {
          delete searchCondition.dept_id
        }
        let {list} = await this.$api.getShareOptionList(searchCondition)
          this.options = list || []

      }

    },
    loadOption() {
      // this.$notify.warning('foc')
      if (!(this.options && this.options.length > 0))
        this.getOptions()
    },
    async getStatus() {
      let {list} = await this.$api.getShareStatusOptionList()
      this.statusOptions = list || []
    }
  },
  async mounted() {
    this.searchCondition.type = this.isType
    this.changeQuarter()
    await this.initMonth()
    await this.doSearch()
    await this.getStatus()

  }
}
</script>

<style scoped>

</style>
